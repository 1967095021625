<template>
	<v-container class="pa-0 indigo " style="height: 100%;">
	
		<div
		>
			<div
				class="text-center"
				style="padding: 100px 0 50px; color: white;"
			> 
				<h2>PIN번호 로그인</h2>
				
				<div class="mt-3">
					<template
						v-for="(val, key) in pin"
					>
						<v-icon
							v-model="pin[key]"
							v-if="val != ''"
							:key="key"
							class="ma-3"
							color="teal lighten-2">mdi-checkbox-blank-circle</v-icon>
						
						<v-icon
							v-model="pin[key]"
							v-if="val == ''"
							:key="key"
							class="ma-3"
							color="grey">mdi-checkbox-blank-circle</v-icon>
					</template>
				</div>
				
			</div>
			
		</div>
			
		<div
			style="position: fixed; bottom: 0;"
		>
			<div
				class="text-center mb-5"
				style="font-size: 0.8em; width: 80%; margin: 0 auto; color: white;"
			>
				<p>PIN번호가 기억이 안나세요?</p>
				<v-btn
					color="blue-grey lighten-1"
					block
					style="color: white;"
					@click="toLogin"
				>
					일반 로그인
				</v-btn>
			</div>
			
			<v-divider></v-divider>
	
			<v-row 	
				class="ma-0 white"
			>
				<template
					v-for="(val, key) in pin_number"
				>
					<v-col

						cols="4"
						v-if="key != 11"
						:key="key"
						class="text-center"
						style="border-right: 1px solid #ddd; border-bottom: 1px solid #ddd; font-size: 0.8em;"
						@click="setPin(key)"
					>
						<v-btn
							class="pa-0 ma-0"
							text
							width="100%"
							active-color="success2"
						>{{ val }}</v-btn>
					</v-col>
					
					<v-col
						cols="4"
						v-if="key == 11"
						:key="key"
						class="text-center"
						style="border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;"
						@click="setPin(key)"
					>
						<v-btn
							class="pa-0 ma-0"
							text
							width="100%"
						><v-icon>{{ val }}</v-icon></v-btn>
					</v-col>
				</template>
			</v-row>
		</div>
		
	</v-container>
</template>


<script>

	import { Base64 } from 'js-base64'

	export default{
		name: 'Pin'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
			try{
				const pin_use = window.Android.getPinCodeUse()
				if(pin_use != 'Y'){
//					this.$router.push('Login')
				}
			}catch(e){
				console.log(e)
				this.$emit('alertMsg', 'error', 'Pin 로그인은 현재 사용할 수 없습니다.')
				this.$router.push('/Login')
			}
		}
		,props: ['callBack']
		,data: function(){
			return {
				pin: ['', '', '', '']
				,pin_number: [1,2,3,4,5,6,7,8,9, '초기화', 0, 'mdi-backspace-outline']
				,program: {
					name: 'PIN 로그인'
					,not_title: true
					,not_navigation: true
				}
			}
		}
		,methods:{
			setPin: function(call){
				
				if(call == 9){
					for(let i = 0; i < this.pin.length; i++){
						this.$set(this.pin, i, '')
					}
				}else if(call == 10){
					for(let i = 0; i < this.pin.length; i++){
						if(this.pin[i] == ''){
							this.$set(this.pin, i, '0')
							return false
						}else{
							console.log(this.pin[i])
						}
					}
				}else if(call == 11){
					for(let i = this.pin.length - 1; i >= 0; i--){
						if(this.pin[i] != ''){
							console.log(this.pin)
							this.$set(this.pin, i, '')
							return false
						}
					}
				}else{
					for(let i = 0; i < this.pin.length; i++){
						if(this.pin[i] == ''){
							this.$set(this.pin, i, call + 1)
							return false
						}else{
							console.log(this.pin[i])
						}
					}
				}
			}
			,checkPin: function(){
				for(let i = 0; i < this.pin.length; i++){
					if(this.pin[i] == ''){
						return false
					}
				}
				
				const NUA = navigator.userAgent.toLowerCase()
				
				let pin = ''
				
				if(NUA.indexOf('android') > -1){
				
					try{
						pin = window.Android.getPinCode()
						
					}catch(error){
						this.$emit('alertMsg', 'error', '현재 PIN로그인은 사용할 수 없습니다. 일반로그인을 이용해주세요')
					}
					
				}else{
					pin = Base64.decode(localStorage.getItem('paylinkc'))
				}
				
				if(pin == this.pin.join('')){
					this.login()
				}else{
					for(let i = 0; i < this.pin.length; i++){
						this.$set(this.pin, i, '')
					}
					this.$emit('alertMsg', 'error', 'PIN번호가 맞지 않습니다')
				}
				
			}
			,result: function(call){
				if(call.result){
					this.$emit('alertMsg', 'success', '로그인 되었습니다')
					this.$router.push('/Home')
				}else{
					this.$emit('alertMsg', 'error', call.message)
				}
			}
			,toLogin: function(){
				if(confirm('일반로그인 사용시 PIN로그인은 해제됩니다\r\n PIN 재설정시 PIN로그인으로 자동 설정됩니다.\r\n 계속 하시겠습니까?')){
					window.Android.setPinCodeUse('N')
					this.$router.push('/Login')
				}
			}
			,login: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/auth/login'
					,request_params: {
						userId : Base64.decode(Base64.decode(localStorage.getItem('paylinkn')))
						,password : Base64.decode(Base64.decode(localStorage.getItem('paylinkp')))
					}
					,callBack: 'setToken'
				})
			}
			,setToken: function(call){
				
				if(call.result){
					localStorage.setItem('paylinkt', call.items.content.access_token)

					this.$emit('setUser', call.items.content.access_token)
					this.$router.push('/Home')
				}else{
					this.$emit('alertMsg', 'error', '잘못된 로그인 정보입니다. 일반 로그인을 이용해주세요')
				}
			}
		}
		,watch:{
			pin: {
				deep: true
				,handler: 'checkPin'
			}
			,callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'result'){
						this.result(call)
					}else if(call.name == 'setToken'){
						this.setToken(call)
					}
				}
			}
		}
	}
</script>

<style>
	.v-application .indigo {background-color: #15324E !important}
</style>